import { clsx } from "clsx";
import { MouseEventHandler, ReactNode } from "react";

import Markdown from "react-markdown";

import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

export const KeyV = ({
  name,
  value,
  icon,
  onClick,
  forceOnClick,
  className,
  useMarkdown = false,
}: {
  name: string;
  value?: string;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  forceOnClick?: boolean;
  className?: string;
  useMarkdown?: boolean;
}) => {
  useDebugLifecycle("KeyV", {
    data: {
      url: window.location.href,
    },
  });

  const valueElement = useMarkdown ? (
    <div className="prose prose-neutral prose-img:m-0">
      <Markdown
        components={{
          p: ({ children }) => (
            <p className="whitespace-pre-wrap">{children}</p>
          ),
        }}
      >
        {value}
      </Markdown>
    </div>
  ) : (
    <span className="whitespace-pre-wrap">{value}</span>
  );

  return (
    <div
      className={clsx(
        "bg-slate-100 flex flex-col py-2 px-4 rounded-md min-w-24",
        (onClick ?? forceOnClick) && "hover:bg-orange-100 cursor-pointer",
        className,
      )}
      onClick={onClick}
    >
      <span className="font-semibold break-all">
        {icon} {name}
      </span>
      {value && valueElement}
    </div>
  );
};
