import { groupBy } from "lodash";
import { useMemo } from "react";

import { AccountInfo } from "@/api/rest/models/account.ts";
import { Building } from "@/api/rest/models/building.ts";
import { HouseholdWithOwnership } from "@/api/rest/models/household";

import { useHouseholds } from "./useHouseholds.ts";

const calculateEnrichedHouseholds = (
  households: HouseholdWithOwnership[],
  managedBuildings: Building[],
) => {
  const householdsByBuilding = groupBy(
    households,
    (household) => household.buildingId,
  );

  const enrichedBuildings = managedBuildings.map((building) => ({
    ...building,
    households: householdsByBuilding[building.id] ?? [],
  }));

  // Find households not associated with managed buildings
  const managedBuildingIds = new Set(
    enrichedBuildings.map((building) => building.id),
  );
  const householdsWithoutBuilding = households.filter(
    (household) => !managedBuildingIds.has(household.buildingId),
  );

  if (householdsWithoutBuilding.length > 0) {
    return [
      {
        id: -1,
        street: null,
        households: householdsWithoutBuilding,
      },
      ...enrichedBuildings,
    ];
  }
  return enrichedBuildings;
};

export const useEnrichedHouseholds = (account: AccountInfo | null) => {
  const households = useHouseholds();

  // First memo for managed buildings
  const managedBuildings = useMemo(() => {
    return account?.accounts.flatMap((acc) => acc.managedBuildings) ?? [];
  }, [account?.accounts]);

  // Second memo for the final calculation
  return useMemo(() => {
    return calculateEnrichedHouseholds(households, managedBuildings);
  }, [households, managedBuildings]);
};
