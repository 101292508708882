import { Outlet } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { useAccount } from "@/api/hooks/useAccount";
import { useEnrichedHouseholds } from "@/api/hooks/useEnrichedHouseholds";
import { fetchHouseholdData } from "@/api/rest/services/householdService";
import Navbar from "@/components/Navbar";
import { debug } from "@/utils/debug/logger";
import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

export default function LoggedInPage() {
  const { householdId } = useParams<{ householdId: string }>();

  const account = useAccount();

  const households = useEnrichedHouseholds(account);

  const currentHousehold = useQuery({
    queryKey: ["household", householdId],
    queryFn: () => fetchHouseholdData(Number(householdId)),
    enabled: Boolean(householdId),
  });

  debug.log("LoggedInPage", { households, currentHousehold, account });

  useDebugLifecycle("LoggedInPage");

  return (
    <>
      <div className="h-screen w-screen overflow-y-auto md:overflow-hidden flex flex-col md:flex-row justify-stretch">
        <Navbar
          households={households}
          currentHousehold={currentHousehold}
          account={account}
        />
        <Outlet context={{ currentHousehold, account }} />
      </div>
    </>
  );
}
