import { HomeModernIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { Fragment, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Household } from "@/api/rest/models/household";
import { debug } from "@/utils/debug/logger";

type NavbarMenuProps = {
  isOpen: boolean;
  accountName: string;
  households: any[];
  onNavigate: (path: string) => void;
  onLogout: () => void;
};

// Updated NavbarMenu Component
export const NavbarMenu = memo(function NavbarMenu({
  isOpen,
  accountName,
  households,
  onNavigate,
  onLogout,
}: NavbarMenuProps) {
  const { t } = useTranslation();

  const translations = useMemo(
    () => ({
      myResidences: t("home.myResidences"),
      logout: t("login.logout"),
    }),
    [t],
  ); // Only recreate when t function changes

  // Base Layout & Responsiveness
  const baseLayoutClasses = clsx(
    // Container basics
    "flex-col justify-stretch",
    // Responsive display
    "md:flex",
    isOpen ? "flex" : "hidden",
    // Padding
    "pb-8",
  );

  // Scrollbar Configuration
  const scrollbarClasses = clsx(
    "md:overflow-y-scroll",
    "scrollbar-thin",
    "scrollbar-track-white",
    "scrollbar-thumb-slate-100",
    "md:flex-grow",
  );

  // Menu Item Base Styles (Account & Building Headers)
  const menuHeaderClasses = clsx(
    // Spacing
    "px-4 py-2",
    // Typography
    "font-bold text-lg",
    // Interaction
    "cursor-pointer",
    // Hover states
    "hover:bg-gray-100 hover:text-dobu-orange-focus",
  );

  // Nested Household Item Styles
  const householdItemClasses = clsx(
    // Indentation & Spacing
    "pl-12 pr-8 py-1",
    // Typography
    "text-gray-700",
    // Interaction
    "cursor-pointer",
    // Hover states
    "hover:bg-gray-100 hover:text-dobu-orange-focus",
  );

  // Icon Styles
  const iconClasses = clsx(
    // Size
    "h-6 w-6",
    // Display & Alignment
    "inline-block",
    "mr-2 -mt-1",
  );

  // Logout Section Container
  const logoutContainerClasses = clsx(
    // Layout
    "flex flex-row justify-center",
    // Spacing
    "mt-3",
  );

  // Logout Button
  const logoutButtonClasses = clsx(
    // Shape & Size
    "rounded-md",
    // Padding
    "px-4 py-1",
    // Border
    "!border-dobu-black border-2",
    // Colors
    "!bg-white",
    "!text-dobu-black",
    // Typography
    "text-sm",
    // Hover state
    "hover:!bg-gray-200",
  );

  // Spacer Utility
  const spacerClasses = clsx("hidden md:flex flex-grow");

  debug.log("NavbarMenu", "Props", {
    isOpen,
    accountName,
    households,
  });

  return (
    <div className={clsx(baseLayoutClasses, scrollbarClasses)}>
      {accountName && (
        <span
          className={menuHeaderClasses}
          onClick={() => onNavigate(`/account`)}
        >
          <UserCircleIcon className={iconClasses} />
          {accountName}
        </span>
      )}

      {households.map((building) => (
        <Fragment key={building.id}>
          <span
            className={menuHeaderClasses}
            onClick={() => {
              if (building.id !== -1) {
                onNavigate(`/building/${building.id}`);
              } else {
                onNavigate(`/household`);
              }
            }}
          >
            <HomeModernIcon className={iconClasses} />
            {building.id !== -1 ? building.street : translations.myResidences}
          </span>
          <ul>
            {building.households.map((household: Household) => (
              <li
                className={householdItemClasses}
                key={household.id}
                onClick={() => onNavigate(`/household/${household.id}`)}
              >
                {household.title}
              </li>
            ))}
          </ul>
        </Fragment>
      ))}

      <span className={spacerClasses} />

      <div className={logoutContainerClasses}>
        <button className={logoutButtonClasses} onClick={onLogout}>
          {translations.logout}
        </button>
      </div>
    </div>
  );
});
