import { forwardRef, memo, useEffect, useRef } from "react";

import { Document } from "@/api/rest/models/document";
import { getDocumentUrl2 } from "@/api/rest/services/documentService";
import { isDesktopSafari } from "@/utils/platform";

interface PDFViewerFrameProps {
  document: Document;
  className?: string;
  refreshKey: number;
  onLoad: () => void;
  useGoogleViewer?: boolean;
}

export const PDFViewerFrame = memo(
  forwardRef<HTMLIFrameElement, PDFViewerFrameProps>(function PDFViewerFrame(
    { document, className, refreshKey, onLoad, useGoogleViewer },
    ref,
  ) {
    const loadFiredRef = useRef(false);
    const url = useGoogleViewer
      ? `https://docs.google.com/gview?embedded=true&rand=${refreshKey}&url=${getDocumentUrl2(document, true)}`
      : getDocumentUrl2(document);

    useEffect(() => {
      if (isDesktopSafari() && !useGoogleViewer && !loadFiredRef.current) {
        // Small delay to ensure PDF starts loading
        const timer = setTimeout(() => {
          loadFiredRef.current = true;
          onLoad();
        }, 100);
        return () => clearTimeout(timer);
      }
    }, [onLoad, useGoogleViewer]);

    if (isDesktopSafari() && !useGoogleViewer) {
      return (
        <embed
          src={url}
          type="application/pdf"
          className={className ?? ""}
          style={{ width: "100%", height: "100%" }}
          ref={ref as React.Ref<HTMLEmbedElement>}
        />
      );
    }

    return (
      <iframe
        key={refreshKey}
        src={url}
        className={className ?? ""}
        onLoad={onLoad}
        ref={ref}
        title="PDF Viewer"
        style={{ width: "100%", height: "100%" }}
      />
    );
  }),
);
