import { useCallback, useEffect, useState } from "react";

import { WarrantyCase } from "@/api/rest/models/warranty";
import {
  getAllCases,
  getAllCasesForBuilding,
} from "@/api/rest/services/warrantyService";

export const useWarrantyCases = (householdId: number) => {
  const [cases, setCases] = useState<WarrantyCase[]>();

  const fetchData = useCallback(async () => {
    const data = await getAllCases(householdId);
    if (data.success) {
      setCases(
        data.result
          .slice()
          .sort((a, b) => b.createdAtTimestamp.diff(a.createdAtTimestamp)),
      );
    }
  }, [householdId]);

  useEffect(() => {
    fetchData().then(null);
  }, [fetchData, householdId]);

  return [cases, fetchData] as const;
};

export const useWarrantyCasesBuilding = (buildingId: number) => {
  const [cases, setCases] = useState<WarrantyCase[]>();

  const fetchData = useCallback(async () => {
    const data = await getAllCasesForBuilding(buildingId);
    if (data.success) {
      setCases(
        data.result
          .slice()
          .sort((a, b) => b.createdAtTimestamp.diff(a.createdAtTimestamp)),
      );
    }
  }, [buildingId]);

  useEffect(() => {
    fetchData().then(null);
  }, [fetchData, buildingId]);

  return [cases, fetchData] as const;
};
