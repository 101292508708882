import { Bars4Icon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import { memo } from "react";

import clsx from "clsx";

import { HouseholdData } from "@/api/rest/models/household";
import DobuLogo from "@/assets/dobu-logo.svg";

import CapitalMillLogo from "../assets/partner-logos/capital-mill.svg";
import DorpatLogo from "../assets/partner-logos/dorpat.svg";
import EmbachLogo from "../assets/partner-logos/embach.svg";
import HepsorLogo from "../assets/partner-logos/hepsor.svg";
import LivenLogo from "../assets/partner-logos/liven.svg";
import RaemoisaLogo from "../assets/partner-logos/raemoisa.svg";
import ReterraLogo from "../assets/partner-logos/reterra.svg";

// Define partner logo mapping at module level to prevent recreation
const LogoMappings = {
  EMBACH: [EmbachLogo],
  LIVEN: [LivenLogo],
  HEPSOR: [HepsorLogo],
  RETERRA: [ReterraLogo],
  RETERRA_RAEMOISA: [ReterraLogo, RaemoisaLogo],
  CAPITAL_MILL: [CapitalMillLogo],
  DORPAT: [DorpatLogo],
} as const;

type NavbarLogoProps = {
  householdData?: HouseholdData;
  onToggleMenu: () => void;
};

export const NavbarLogo = memo(function NavbarLogo({
  householdData,
  onToggleMenu,
}: NavbarLogoProps) {
  const navigate = useNavigate();

  // Get logo mappings based on partner code
  const logoMappings =
    householdData?.building?.partnerCode &&
    LogoMappings[
      householdData.building?.partnerCode as keyof typeof LogoMappings
    ];

  const companyLogo = logoMappings?.[0];
  const developmentLogo = logoMappings?.[1];

  // Layout & Container Styles
  const containerClasses = clsx(
    // Basic layout
    "flex flex-col",
    // Spacing
    "mt-8 mb-4 gap-2",
    // Alignment variations by screen size
    "items-stretch md:items-center",
  );

  // Top Section Layout
  const topSectionClasses = clsx(
    // Basic layout
    "flex flex-row",
    // Alignment
    "justify-between items-center",
  );

  // Menu Icon Base Styles
  const menuIconBaseClasses = clsx(
    // Size and spacing
    "h-10 w-10 p-1",
    // Responsive visibility
    "inline md:hidden",
  );

  // Invisible placeholder for alignment
  const invisibleLeftIconClasses = clsx(
    menuIconBaseClasses,
    "ml-6",
    "invisible",
  );

  // Right Menu Icon (Interactive)
  const rightMenuIconClasses = clsx(
    menuIconBaseClasses,
    // Positioning and sizing
    "mr-6 min-w-10 md:min-w-0",
    // Interaction
    "cursor-pointer",
    // Visual feedback
    "hover:bg-gray-100 hover:text-dobu-orange-focus rounded",
    // Additional responsive control
    "md:invisible",
  );

  // Logo Styles
  const dobuLogoClasses = clsx(
    // Visual
    "invert",
    // Spacing
    "mx-4",
    // Positioning
    "self-center",
    // Interaction
    "cursor-pointer",
  );

  // Partner Logo Styles (Company & Development)
  const partnerLogoClasses = clsx(
    // Responsive display
    "hidden sm:flex md:hidden",
    // Spacing and alignment
    "mx-4 self-center",
  );

  // Divider Styles
  const dividerClasses = clsx(
    // Responsive display
    "hidden md:flex",
    // Border styling
    "border-[0.5px] border-solid border-gray-300 rounded-lg",
    // Width
    "w-1/2",
  );

  // Desktop Logo Container
  const desktopLogoContainerClasses = clsx(
    // Responsive display
    "hidden md:flex",
    // Layout
    "flex-row",
    // Alignment
    "justify-between items-center",
  );

  return (
    <div className={containerClasses}>
      {/* Top Section with Main Logo */}
      <div className={topSectionClasses}>
        <Bars4Icon className={invisibleLeftIconClasses} />
        <img
          className={dobuLogoClasses}
          src={DobuLogo}
          alt="Dobu Logo"
          width={100}
          height={24}
          onClick={() => navigate("/household")}
        />
        {companyLogo && (
          <img
            className={partnerLogoClasses}
            src={companyLogo}
            alt="Company Logo"
            width={100}
            height={24}
          />
        )}
        <Bars4Icon className={rightMenuIconClasses} onClick={onToggleMenu} />
        {developmentLogo && (
          <img
            className={partnerLogoClasses}
            src={developmentLogo}
            alt="Development Logo"
            width={100}
            height={24}
          />
        )}
      </div>

      {/* Divider - Only visible on desktop */}
      {companyLogo && <hr className={dividerClasses} />}

      {/* Company Logo Section - Desktop Only */}
      {companyLogo && (
        <div className={desktopLogoContainerClasses}>
          <Bars4Icon className={invisibleLeftIconClasses} />
          <img
            className="mx-4 self-center"
            src={companyLogo}
            alt="Company Logo"
            width={100}
            height={24}
          />
          <Bars4Icon className={invisibleLeftIconClasses} />
        </div>
      )}

      {/* Development Logo Section - Desktop Only */}
      {developmentLogo && (
        <div className={desktopLogoContainerClasses}>
          <Bars4Icon className={invisibleLeftIconClasses} />
          <img
            className="mx-4 self-center"
            src={developmentLogo}
            alt="Development Logo"
            width={100}
            height={24}
          />
          <Bars4Icon className={invisibleLeftIconClasses} />
        </div>
      )}
    </div>
  );
});
