import { useTranslation } from "next-i18next";

import Spinner from "@/components/common/Spinner";

const DocumentPreviewLoader = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-[300px] bg-gray-50">
      <div className="flex flex-col items-center gap-4">
        <Spinner className="w-12 h-12 text-dobu-orange" />
        <p className="text-gray-600 text-lg">{t("files.loadingPreview")}</p>
      </div>
    </div>
  );
};

export default DocumentPreviewLoader;
