import * as Sentry from "@sentry/react";
import { QueryClient } from "@tanstack/react-query";

import { useHouseholdsGlobal } from "@/api/hooks/useHouseholds";
import { sendMessageToNative } from "@/api/react-native/bridge";
import { fetchUsingApi, routerInstance } from "@/api/rest/core/apiClient";
import { ApiError, ApiErrorType, ApiResponse } from "@/api/rest/core/types";
import i18n from "@/i18n";
import { debug } from "@/utils/debug/logger";
import { sendToSentry } from "@/utils/sentry";
import { getToken, removeToken, setToken } from "@/utils/tokenManager";

import { useAccountGlobal } from "@/api/hooks/useAccount";

import { apiBase, fetchWithAuth } from "../core/apiClient";
import {
  ChangeEmailRequest,
  ChangeLanguageRequest,
  ChangePasswordRequest,
  ConfirmEmailChangeRequest,
  ConfirmEmailChangeResult,
  ForgotPasswordRequest,
  GoogleLoginRequest,
  LoginRequest,
  ResetPasswordRequest,
  UnsubscribeResponse,
} from "../models/auth";

interface AuthResponse {
  accessToken: string;
  refreshToken: string;
}

const queryClient = new QueryClient();

/**
 * Generic handler for authentication requests
 */
const handleAuthRequest = async (
  url: string,
  request: LoginRequest | GoogleLoginRequest,
): Promise<boolean> => {
  return Sentry.startSpan(
    {
      name: `Auth Request ${url}`,
      op: "auth.request",
      attributes: {
        "auth.type": url.includes("google") ? "google" : "password",
        "auth.endpoint": url,
      },
    },
    async (span) => {
      let response: Response | undefined;
      let responseData: AuthResponse | undefined;

      try {
        removeToken();
        queryClient.clear();
        useHouseholdsGlobal.getState().clear();
        useAccountGlobal.getState().clear();

        response = await fetch(`${apiBase}${url}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(request),
        });

        // Add response status to span
        span?.setAttributes({
          "http.status_code": response.status,
        });

        if (response.status === 200) {
          responseData = (await response.json()) as AuthResponse;

          sendMessageToNative({
            type: "LOGIN",
            accessToken: responseData.accessToken,
            refreshToken: responseData.refreshToken,
          });
          setToken(responseData.accessToken);

          return true;
        }

        // Handle authentication errors
        const errorMessage =
          response.status === 401 || response.status === 403
            ? i18n.t("login.loginError")
            : i18n.t("common.error.500");

        throw new Error(errorMessage);
      } catch (e) {
        const error = e instanceof Error ? e : new Error(String(e));

        sendToSentry(error, {
          url: `${apiBase}${url}`,
          method: "POST",
          response,
          responseBody: responseData,
          additionalContext: {
            component: "handleAuthRequest",
            authType: url.includes("google") ? "google" : "password",
            phase: response ? "response-processing" : "request",
            email: "email" in request ? request.email : undefined,
            requestBody: {
              ...request,
              // Exclude sensitive fields
              password: undefined,
              credential: undefined,
            },
          },
        });

        throw error;
      }
    },
  );
};

/**
 * Handles standard password-based login
 */
export const login = async (request: LoginRequest): Promise<boolean> => {
  return handleAuthRequest("/auth/login", request);
};

/**
 * Handles Google OAuth login
 */
export const googleLogin = async (
  request: GoogleLoginRequest,
): Promise<boolean> => {
  return handleAuthRequest("/auth/google", request);
};

/**
 * Handles user logout with proper cleanup
 */
export const logout = async (): Promise<boolean> => {
  return Sentry.startSpan(
    {
      name: "Logout",
      op: "auth.logout",
    },
    async () => {
      try {
        sendMessageToNative({
          type: "LOGOUT",
        });

        await fetchWithAuth(`${apiBase}/auth/logout`, {
          method: "POST",
        });

        removeToken();
        routerInstance?.navigate("/login");
        queryClient.clear();
        useHouseholdsGlobal.getState().clear();
        useAccountGlobal.getState().clear();

        return true;
      } catch (error) {
        debug.warn("logout", error);
        const err = error instanceof Error ? error : new Error(String(error));

        sendToSentry(err, {
          additionalContext: {
            component: "logout",
            hadToken: Boolean(getToken()),
          },
        });
        // Force cleanup and redirect even on error
        removeToken();
        routerInstance?.navigate("/login");
        return false;
      }
    },
  );
};

export async function changePassword(
  request: ChangePasswordRequest,
): Promise<boolean> {
  try {
    const response = await fetchWithAuth(`${apiBase}/auth/change-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
      requestContext: "AUTH",
      errorHandlers: {
        401: () => {
          throw new ApiError(
            ApiErrorType.UNAUTHORIZED,
            i18n.t("login.error.invalidOldPassword"),
          );
        },
      },
    });
    if (!response.ok) {
      throw new ApiError(ApiErrorType.SERVER_ERROR, i18n.t("common.error.500"));
    }
    return true;
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    throw new ApiError(ApiErrorType.SERVER_ERROR, i18n.t("common.error.500"));
  }
}

export const forgotPassword = async (
  request: ForgotPasswordRequest,
): Promise<boolean> => {
  let result;
  try {
    result = await fetchWithAuth(`${apiBase}/auth/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  } catch (e) {
    console.log("1", e);
    throw i18n.t("common.error.500");
  }
  if (result.status === 200) {
    return true;
  }
  throw i18n.t("common.error.500");
};

export const resetPassword = async (
  request: ResetPasswordRequest,
): Promise<boolean> => {
  let result;
  try {
    result = await fetchWithAuth(`${apiBase}/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  } catch (e) {
    console.log("1", e);
    throw i18n.t("common.error.500");
  }
  if (result.status === 200) {
    return true;
  }
  throw i18n.t("common.error.500");
};

export const changeEmail = async (
  request: ChangeEmailRequest,
): Promise<ApiResponse<boolean>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/change-email`,
    "POST",
    () => true,
    JSON.stringify(request),
  );
};

export const confirmEmailChange = async (
  request: ConfirmEmailChangeRequest,
): Promise<ApiResponse<ConfirmEmailChangeResult>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/confirm-email-change`,
    "POST",
    (e) => e as ConfirmEmailChangeResult,
    JSON.stringify(request),
  );
};

export const changeLanguage = async (
  request: ChangeLanguageRequest,
): Promise<ApiResponse<boolean>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/change-language`,
    "POST",
    () => true,
    JSON.stringify(request),
  );
};

export const unsubscribe = async (
  token: string,
): Promise<ApiResponse<UnsubscribeResponse>> => {
  return fetchUsingApi(
    `${apiBase}/api/v1/notifications/unsubscribe/${token}`,
    "POST",
    (result) => result as UnsubscribeResponse,
  );
};
