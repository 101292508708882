import { Dialog } from "@headlessui/react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";

import { enrichDocument } from "@/api/rest/utils";
import { randomString } from "@/components/common/helper.ts";
import Spinner from "@/components/common/Spinner.tsx";
import DocumentsList from "@/components/household-contents/document/DocumentsList";

import { ApiResponse } from "../api/rest/core/types";

export default function UploadFilesPopup(
  {
    children,
    className,
    uploadCallback,
    updateHousehold,
  }: {
    children: ReactNode;
    className?: string;
    uploadCallback: (result: File[]) => Promise<ApiResponse<unknown>>;
    updateHousehold?: () => void;
  },
  singleFileOnly?: boolean,
) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [uploadResult, setUploadResult] = useState<ApiResponse<unknown> | null>(
    null,
  );
  const [isUploading, setIsUploading] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDropAccepted: (files) =>
      setAcceptedFiles((oldFiles) =>
        singleFileOnly ? [...files] : [...oldFiles, ...files],
      ),
  });

  const uploadFiles = async () => {
    setIsUploading(true);
    const result = await uploadCallback(acceptedFiles);
    setUploadResult(result);
    setIsUploading(false);
  };

  const closePopup = useCallback(() => {
    setIsOpen(false);
    setUploadResult(null);
    setAcceptedFiles([]);
    setIsUploading(false);
  }, [setIsOpen, setUploadResult]);

  const fakeDocuments = useMemo(() => {
    return acceptedFiles
      .map((file) => ({
        id: -1,
        originalFilename: file.path ?? file.name,
        hash: randomString(32),
        processingStatus: "NOT_UPLOADED",
        mimeType: file.type,
      }))
      .map(enrichDocument);
  }, [acceptedFiles]);

  useEffect(() => {
    if (!isUploading && uploadResult?.success) {
      closePopup();
      updateHousehold?.();
    }
  }, [uploadResult, isUploading, closePopup, updateHousehold]);

  return (
    <div className={className} onClick={() => setIsOpen(true)}>
      {children}
      <Dialog open={isOpen} onClose={closePopup} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto rounded-lg bg-white flex flex-col shadow-xl overflow-clip min-w-[50%] max-w-[75%] max-h-[80vh]">
            <Dialog.Title className="text-lg p-6 bg-slate-100 font-medium leading-6 text-gray-900 flex flex-row">
              <span>{t("files.addFiles")}</span>
            </Dialog.Title>

            <div className="flex flex-col overflow-hidden">
              <div
                {...getRootProps()}
                className={clsx(
                  // "border-2 border-dashed border-gray-300 rounded-md p-4",
                  "flex flex-col items-center px-4 py-8 hover:bg-orange-100 focus-visible:bg-orange-100 cursor-pointer",
                  isDragAccept && "bg-orange-300",
                  uploadResult?.success && "hidden",
                )}
              >
                <input {...getInputProps()} />
                <DocumentDuplicateIcon className="h-24 w-24 text-gray-500" />
                <span className="text-gray-500 text-center pt-8">
                  <Trans
                    i18nKey="files.addFilesInstructions"
                    components={{
                      span1: <span className="font-semibold" />,
                      span2: <span className="underline font-semibold" />,
                    }}
                  />
                </span>
              </div>
              {acceptedFiles.length > 0 && (
                <div className="flex flex-col items-stretch overflow-y-auto gap-2 p-4">
                  <DocumentsList
                    documents={fakeDocuments}
                    itemsClassName="bg-slate-100"
                    allowDownload={false}
                  />
                </div>
              )}
              {!isUploading && uploadResult && (
                <div
                  className={clsx(
                    "p-2 mx-2 mb-2 text-center rounded-md",
                    uploadResult.success
                      ? "bg-green-200 text-green-900"
                      : "bg-red-200 text-red-900",
                  )}
                >
                  <span>
                    {uploadResult.success
                      ? t("files.uploading")
                      : uploadResult.message}
                  </span>
                </div>
              )}
              {!isUploading &&
                acceptedFiles.length > 0 &&
                !uploadResult?.success && (
                  <button
                    onClick={uploadFiles}
                    className="rounded-md mx-2 mb-2 p-2 bg-dobu-orange text-white hover:bg-dobu-orange-focus focus-visible:bg-dobu-orange-focus"
                  >
                    {t("files.save")}
                  </button>
                )}
              {isUploading && (
                <span className="flex flex-row mx-2 mb-2">
                  <span className="flex-grow bg-orange-100 text-center rounded-md p-2">
                    <Spinner className="w-6 h-6 inline mr-2" />
                    {t("files.uploading")}
                  </span>
                </span>
              )}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}
