import React, { useMemo, useRef } from "react";

import { isMobileDevice, isWebView } from "@/utils/platform";

import { ViewerProps } from "../../types";
import { PDFViewerFrame } from "./PDFViewerFrame";
import { usePDFLoading } from "./usePDFLoading";

export const PDFViewer: React.FC<ViewerProps> = ({
  document,
  onSuccess,
  onError,
  className,
}) => {
  // Create a ref for the iframe
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Determine whether to use Google Docs Viewer based on the device/platform
  const useGooglePdfPreview = useMemo(() => {
    return isMobileDevice() || isWebView();
  }, []);

  // Utilize the enhanced PDF loading hook with the iframe ref
  const { refreshKey, handleLoad } = usePDFLoading({
    onSuccess,
    onError,
    iframeRef,
  });

  return (
    <PDFViewerFrame
      document={document}
      className={className}
      refreshKey={refreshKey}
      onLoad={handleLoad}
      useGoogleViewer={useGooglePdfPreview}
      ref={iframeRef}
    />
  );
};
