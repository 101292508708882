import { getDocumentUrl2 } from "@/api/rest/services/documentService";

import { ViewerProps } from "../types";

export const ImageViewer: React.FC<ViewerProps> = ({
  document,
  onSuccess,
  className,
}) => {
  return (
    <img
      src={getDocumentUrl2(document)}
      className={`${className} object-contain`}
      onLoad={onSuccess}
      alt={document.fileName}
    />
  );
};

export const isImageDocument = (type: string): boolean => {
  return ["png", "jpg", "jpeg"].includes(type);
};
