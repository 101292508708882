import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { QueryObserverResult } from "@tanstack/react-query";

import { useWarrantyCases } from "@/api/hooks/useWarrantyCases.ts";
import { AccountInfo } from "@/api/rest/models/account";
import { HouseholdData } from "@/api/rest/models/household";
import { Banner } from "@/components/common/Banner.tsx";
import dayjs from "@/components/dayjsExtended.ts";
import ListClaims from "@/components/warranty-contents/ListClaims.tsx";
import NewClaim from "@/components/warranty-contents/NewClaim.tsx";

export default function WarrantyPage() {
  const navigate = useNavigate();
  const { householdId } = useParams<{ householdId: string }>();
  const [cases, fetchCases] = useWarrantyCases(Number(householdId));
  const { t } = useTranslation();

  const { currentHousehold } = useOutletContext<{
    account: AccountInfo;
    currentHousehold: {
      isLoading: boolean;
      data: {
        result: HouseholdData | undefined;
      };
      refetch: () => Promise<QueryObserverResult>;
    };
  }>();
  const householdData = currentHousehold.data?.result;
  const loading = currentHousehold.isLoading;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!householdData) {
    return <div>Household not found</div>;
  }

  const warrantyValid =
    householdData.household.warrantyEndDateTimestamp.isAfter(dayjs());

  return (
    <main className="flex flex-grow flex-col p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white items-center">
      <div className="flex flex-grow self-stretch flex-col">
        <div className="flex flex-row ">
          <ChevronLeftIcon
            className="h-9 w-9 mr-2 hover:bg-slate-300 focus-visible:bg-dobu-orange cursor-pointer rounded"
            onClick={() => navigate(-1)}
          />
          <h2 className="text-3xl font-bold text-center mr-4">
            {t("warranty.warrantyCases")}
          </h2>
          <Banner
            message={
              warrantyValid
                ? t("warranty.warrantyIsValidUntil", {
                    date: householdData.household.warrantyEndDateTimestamp.format(
                      "MM/YYYY",
                    ),
                  })
                : t("warranty.warrantyPeriodHasExpired")
            }
            success={warrantyValid}
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-8 mt-8">
          <div className="flex flex-grow basis-1/2 flex-col gap-4 items-stretch">
            <ListClaims cases={cases ?? []} />
          </div>
          {warrantyValid && (
            <NewClaim householdData={householdData} refreshCases={fetchCases} />
          )}
        </div>
      </div>
    </main>
  );
}
