// src/components/document/DocumentViewer/types.ts

import { QueryObserverResult } from "@tanstack/react-query";

import { Document } from "@/api/rest/models/document";

export interface ViewerProps {
  document: Document;
  onSuccess: () => void;
  onError: (error: Error) => void;
  className?: string;
}

export interface DocumentViewerProps {
  document: Document;
  buildingId?: number;
  householdId?: number;
  className?: string;
  onSelect?: (url: string, originalName: string) => void;
  allowPreview: boolean;
  allowDelete?: boolean;
  updateHousehold?: () => Promise<QueryObserverResult>;
}

export interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
}

// Utility type guard for document types
export const isPreviewableDocument = (document: Document): boolean => {
  const type = document.fileName.split(".").pop()?.toLowerCase();
  return [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "png",
    "jpg",
    "jpeg",
    "json",
  ].includes(type ?? "");
};

export const getDocumentType = (document: Document): string => {
  return document.fileName.split(".").pop()?.toLowerCase() ?? "";
};
