const isDebugEnabled = () => {
  return import.meta.env.MODE === "development";
};

export const debug = {
  log: (component: string, ...args: unknown[]) => {
    if (!isDebugEnabled()) {
      return;
    }
    console.log(`[${component}]`, ...args);
  },

  warn: (component: string, ...args: unknown[]) => {
    if (!isDebugEnabled()) {
      return;
    }
    console.warn(`[${component}]`, ...args);
  },
};
