// src/components/document/DocumentViewer/viewers/OfficeViewer.tsx

import { getDocumentUrl2 } from "@/api/rest/services/documentService";

import { ViewerProps } from "../types";

export const OfficeViewer: React.FC<ViewerProps> = ({
  document,
  onSuccess,
  onError,
  className,
}) => {
  return (
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${getDocumentUrl2(document, true)}`}
      className={className}
      onLoad={onSuccess}
      onError={() => onError(new Error("Failed to load Office document"))}
    />
  );
};

export const isOfficeDocument = (type: string): boolean => {
  return ["doc", "docx", "xls", "xlsx"].includes(type);
};
