import { PostHogProvider } from "posthog-js/react";

import { UserIdentifier } from "./UserIdentifier";

interface PostHogWrapperProps {
  children: React.ReactNode;
}

export const PostHogWrapper = ({ children }: PostHogWrapperProps) => {
  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY as string}
      options={{ api_host: "https://ph.dobu.ee" }}
    >
      <UserIdentifier />
      {children}
    </PostHogProvider>
  );
};
