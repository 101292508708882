import { useEffect, useRef } from "react";

interface DebugLifecycleOptions {
  // Enable/disable debug logging
  enabled?: boolean;
  // Additional data to log with lifecycle events
  data?: Record<string, unknown>;
}

/**
 * Hook for debugging component lifecycle events
 * @param componentName - Name of the component being debugged
 * @param options - Configuration options for debugging
 */
export const useDebugLifecycle = (
  componentName: string,
  options: DebugLifecycleOptions = {},
) => {
  const { enabled = import.meta.env.MODE === "development", data = {} } =
    options;

  const renderCount = useRef(0);

  useEffect(() => {
    renderCount.current += 1;

    if (!enabled) {
      return;
    }

    if (renderCount.current > 4) {
      console.log(
        `[Lifecycle][${componentName}] Render #${renderCount.current}`,
        data,
      );
    }
  });

  return renderCount.current;
};
