import i18n from "@/i18n";

import { AccountInfo } from "@/api/rest/models/account";

export const initializeUserLanguage = async (account: AccountInfo) => {
  // Only change language if it's different from current
  const currentLang = i18n.language;
  const userLang = account.language || "en";

  if (currentLang !== userLang) {
    await i18n.changeLanguage(userLang);
  }
};
