import { useEffect, useRef, useState } from "react";

import { debug } from "@/utils/debug/logger";

const REFRESH_TIMEOUT = 4000; // 4 seconds between refresh attempts
const MAX_TIMEOUT_ATTEMPTS = 3; // Maximum number of timeout attempts before giving up
const MAX_EMPTY_CONTENT_ATTEMPTS = 15; // Maximum number of empty content attempts before giving up

interface PDFLoadingHookProps {
  onSuccess: () => void;
  onError: (error: Error) => void;
  iframeRef:
    | React.RefObject<HTMLIFrameElement>
    | React.RefObject<HTMLEmbedElement>;
}

interface LoadingState {
  isLoading: boolean;
  refreshKey: number;
  timeoutAttemptCount: number;
  emptyContentAttemptCount: number;
}

export const usePDFLoading = ({
  onSuccess,
  onError,
  iframeRef,
}: PDFLoadingHookProps) => {
  const [loadingState, setLoadingState] = useState<LoadingState>({
    isLoading: true,
    refreshKey: 0,
    timeoutAttemptCount: 0,
    emptyContentAttemptCount: 0,
  });

  const refreshTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isLoadedRef = useRef(false);

  const clearTimers = () => {
    if (refreshTimerRef.current) {
      clearTimeout(refreshTimerRef.current);
      refreshTimerRef.current = null;
    }
  };

  useEffect(() => {
    clearTimers();

    if (
      loadingState.isLoading &&
      loadingState.timeoutAttemptCount < MAX_TIMEOUT_ATTEMPTS
    ) {
      refreshTimerRef.current = setTimeout(() => {
        if (!isLoadedRef.current) {
          debug.log(
            "usePDFLoading",
            `Refreshing PDF viewer (attempt ${loadingState.timeoutAttemptCount + 1}/${MAX_TIMEOUT_ATTEMPTS})`,
          );
          setLoadingState((prev) => ({
            ...prev,
            isLoading: true,
            refreshKey: prev.refreshKey + 1,
            timeoutAttemptCount: prev.timeoutAttemptCount + 1,
          }));
        }
      }, REFRESH_TIMEOUT);
    } else if (
      loadingState.timeoutAttemptCount >= MAX_TIMEOUT_ATTEMPTS &&
      loadingState.isLoading
    ) {
      debug.log(
        "usePDFLoading",
        `PDF loading failed after ${MAX_TIMEOUT_ATTEMPTS} attempts`,
      );
      onError(
        new Error(`PDF loading failed after ${MAX_TIMEOUT_ATTEMPTS} attempts`),
      );
      setLoadingState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }

    return clearTimers;
  }, [loadingState, onError]);

  const handleLoad = () => {
    debug.log("iframeRef.current", iframeRef.current);
    // if HTMLEmbedElement, assume successful load
    if (iframeRef.current instanceof HTMLEmbedElement) {
      debug.log(
        "usePDFLoading",
        "HTMLEmbedElement detected, assuming successful load.",
      );
      onSuccess();
      clearTimers();
      setLoadingState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      return;
    }
    debug.log(
      "iframeRef.current?.contentWindow",
      iframeRef.current?.contentWindow,
    );
    if (iframeRef.current) {
      if (iframeRef.current.contentWindow) {
        if (iframeRef.current.contentWindow.length > 0) {
          debug.log(
            "usePDFLoading",
            iframeRef.current.contentWindow.length,
            "assuming successful load.",
          );
          onSuccess();
        } else {
          if (
            loadingState.emptyContentAttemptCount < MAX_EMPTY_CONTENT_ATTEMPTS
          ) {
            debug.log(
              "usePDFLoading",
              `Empty content detected, refreshing PDF viewer (attempt ${loadingState.emptyContentAttemptCount + 1}/${MAX_EMPTY_CONTENT_ATTEMPTS})`,
              "currentTime in ms",
              Date.now(),
            );
            setTimeout(() => {
              setLoadingState((prev) => ({
                ...prev,
                isLoading: true,
                refreshKey: prev.refreshKey + 1,
                emptyContentAttemptCount: prev.emptyContentAttemptCount + 1,
              }));
            }, 1000);
            return;
          }
          debug.log(
            "usePDFLoading",
            `PDF loading failed after ${MAX_EMPTY_CONTENT_ATTEMPTS} attempts`,
          );
          onError(
            new Error(
              `PDF loading failed after ${MAX_EMPTY_CONTENT_ATTEMPTS} attempts`,
            ),
          );
          setLoadingState((prev) => ({
            ...prev,
            isLoading: false,
          }));
        }
      }
    }

    if (!isLoadedRef.current) {
      isLoadedRef.current = true;
      clearTimers();
      setLoadingState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  return {
    isLoading: loadingState.isLoading,
    refreshKey: loadingState.refreshKey,
    handleLoad,
  };
};
