import { useCallback, useEffect, useState } from "react";

import { ApiResponse } from "@/api/rest/core/types";
import { HouseholdData } from "@/api/rest/models/household";
import { debug } from "@/utils/debug/logger";

interface UseSubmissionFormProps {
  householdData: HouseholdData;
}

export const useSubmissionForm = ({
  householdData,
}: UseSubmissionFormProps) => {
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  const [isSubmittingCase, setSubmittingState] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean | null>(null);

  const FileUploadMandatoryList = {
    RETERRA_RAEMOISA: true,
    CAPITAL_MILL: true,
  };

  debug.log(
    "useSubmissionForm",
    "householdData?.building.partnerCode",
    householdData?.building.partnerCode,
  );
  const isFileUploadMandatory = householdData?.building.partnerCode
    ? FileUploadMandatoryList[
        householdData.building
          .partnerCode as keyof typeof FileUploadMandatoryList
      ]
    : false;

  debug.log(
    "useSubmissionForm",
    "isFileUploadMandatory",
    isFileUploadMandatory,
  );

  useEffect(() => {
    if (pendingFiles.length > 0) {
      debug.log("Files pending", pendingFiles);
      setIsFileUploaded(true);
    }
  }, [pendingFiles]);

  debug.log("isFileUploaded", isFileUploaded);

  const submitFiles = useCallback(
    async (files: File[]): Promise<ApiResponse<unknown>> => {
      setPendingFiles((prevFiles) => [...prevFiles, ...files]);
      return Promise.resolve({ success: true, result: null });
    },
    [],
  );

  const resetSubmission = useCallback(() => {
    setPendingFiles([]);
    setIsFileUploaded(null);
    setSubmittingState(false);
  }, []);

  return {
    pendingFiles,
    setPendingFiles,
    isSubmittingCase,
    setSubmittingState,
    isFileUploaded,
    setIsFileUploaded,
    isFileUploadMandatory,
    submitFiles,
    resetSubmission,
  };
};
