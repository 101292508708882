import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  EntityConfiguration,
  EntityConfigurationType,
  useUpdateConfiguration,
} from "@/api/rest/services/configurationService";
import Spinner from "@/components/common/Spinner";

export default function ConfigurationEditor({
  conf,
  refreshConf,
}: {
  conf: EntityConfiguration;
  refreshConf: () => void;
}) {
  const [newConf, setNewConf] = useState(conf.value);
  const isDirty = newConf !== conf.value;
  const { t } = useTranslation();

  const { mutate: updateConf, isPending } = useUpdateConfiguration();

  const saveConf = useCallback(
    (newConfValue: string) => {
      updateConf(
        {
          buildingId: conf.entityId,
          configurationType: conf.type,
          configurationValue: newConfValue,
        },
        {
          onSuccess: () => {
            refreshConf();
          },
          onError: (error) => {
            console.error("Failed to update configuration:", error);
          },
        },
      );
    },
    [conf, refreshConf, updateConf],
  );

  const getConfTypeName = (type: EntityConfigurationType): string => {
    switch (type) {
      case EntityConfigurationType.WELCOME_EMAIL:
        return t("building.welcomeEmail");
      case EntityConfigurationType.WARRANTY_PROMPT:
        return t("building.warrantyRules");
      default:
        return type;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <span className="flex flex-row gap-2 items-center">
        <h3 className="font-bold inline-flex">{getConfTypeName(conf.type)}</h3>
        {isDirty && (
          <button
            className={`rounded px-4 py-2 flex items-center gap-2 ${
              isPending ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-100"
            }`}
            onClick={() => saveConf(newConf)}
            disabled={isPending}
          >
            {isPending && <Spinner className="w-4 h-4" />}
            {t("settings.save")}
          </button>
        )}
      </span>
      <textarea
        value={newConf}
        onChange={(e) => setNewConf(e.target.value)}
        className="w-full min-h-[20vh] p-2 rounded border border-gray-300 focus:border-dobu-orange focus:ring-1 focus:ring-dobu-orange"
        disabled={isPending}
      />
    </div>
  );
}
