import { useCallback, useEffect } from "react";
import { create } from "zustand";

import { HouseholdWithOwnership } from "../rest/models/household";
import { fetchAllHouseholds } from "../rest/services/householdService";

interface HouseholdsState {
  households: HouseholdWithOwnership[];
  loading: boolean;
}

interface HouseholdActions {
  load: () => Promise<void>;
  clear: () => void;
}

export const useHouseholdsGlobal = create<HouseholdsState & HouseholdActions>(
  (set) => {
    return {
      households: [],
      loading: false,
      load: async () => {
        const state = useHouseholdsGlobal.getState();

        if (state.loading) {
          return;
        }

        set({ loading: true });
        const result = await fetchAllHouseholds();
        if (result.success) {
          set((state) => {
            const newHouseholds = result.result
              .slice()
              .sort((a, b) => a.id - b.id);

            // If same length and same IDs in same order, don't update
            if (
              state.households.length === newHouseholds.length &&
              state.households.every((h, i) => h.id === newHouseholds[i].id)
            ) {
              return { ...state, loading: false };
            }

            return { households: newHouseholds, loading: false };
          });
        } else {
          set({ loading: false });
        }
      },
      clear: () => {
        set({ households: [], loading: false });
      },
    };
  },
);

export const useHouseholds = () => {
  const households = useHouseholdsGlobal(
    useCallback((state) => state.households, []),
  );
  const load = useHouseholdsGlobal(useCallback((state) => state.load, []));

  useEffect(() => {
    if (households.length < 1) {
      load().then(null);
    }
  }, [households, load]);

  return households;
};
