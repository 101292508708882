import { useCallback, useEffect, useRef } from "react";
import { create } from "zustand";

import { initializeUserLanguage } from "@/utils/i18n/initializeLanguage";

import { AccountInfo } from "../rest/models/account";
import { fetchAccountInfo } from "../rest/services/accountService";

interface AccountState {
  account: AccountInfo | null;
  loading: boolean;
}

interface AccountActions {
  load: () => Promise<void>;
  clear: () => void;
}

export const useAccountGlobal = create<AccountState & AccountActions>((set) => {
  return {
    account: null,
    loading: false,
    load: async () => {
      const state = useAccountGlobal.getState();

      if (state.loading || state.account) {
        return;
      }

      set({ loading: true });
      const account = await fetchAccountInfo();
      if (account.success) {
        set((state) => {
          // Only update if the account is different
          if (state.account?.id === account.result.id) {
            return state;
          }
          return { account: account.result, loading: false };
        });
        await initializeUserLanguage(account.result);
      } else {
        set({ loading: false });
      }
    },
    clear: () => {
      set({ account: null, loading: false });
    },
  };
});

export const useAccount = () => {
  const loadingRef = useRef(false);
  const account = useAccountGlobal(useCallback((state) => state.account, []));
  const load = useAccountGlobal(useCallback((state) => state.load, []));

  // Use useEffect with proper dependencies
  useEffect(() => {
    if ((account === null || account === undefined) && !loadingRef.current) {
      loadingRef.current = true;
      const loadData = async () => {
        await load();
        loadingRef.current = false;
      };
      loadData();
    }
  }, [account, load]);

  return account;
};
