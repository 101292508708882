// src/components/document/DocumentViewer/index.tsx

import { useState } from "react";

import DocumentPreviewLoader from "@/components/household-contents/document/DocumentsPreviewLoader";

import {
  downloadDocument2,
  getDocumentUrl2,
} from "@/api/rest/services/documentService";

import DocumentError from "./DocumentError";
import { DocumentViewerProps, getDocumentType } from "./types";
import { ImageViewer, isImageDocument } from "./viewers/ImageViewer";
import { JSONViewer, isJSONDocument } from "./viewers/JSONViewer";
import { OfficeViewer, isOfficeDocument } from "./viewers/OfficeViewer";
import { PDFViewer } from "./viewers/pdf/PDFViewer";

export const DocumentViewer: React.FC<DocumentViewerProps> = ({
  document,
  className = "flex-grow",
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const type = getDocumentType(document);

  const viewerClassName = `${className} ${isLoading ? "hidden" : "block"}`;

  const handleDownload = () => {
    downloadDocument2(getDocumentUrl2(document), document.originalFilename);
  };

  const handleSuccess = () => {
    setIsLoading(false);
    setError(false);
  };
  const handleError = () => {
    setIsLoading(false);
    setError(true);
  };

  const renderViewer = () => {
    const viewerProps = {
      document,
      onSuccess: handleSuccess,
      onError: handleError,
      className: viewerClassName,
    };

    if (type === "pdf") {
      return <PDFViewer {...viewerProps} />;
    }
    if (isOfficeDocument(type)) {
      return <OfficeViewer {...viewerProps} />;
    }
    if (isImageDocument(type)) {
      return <ImageViewer {...viewerProps} />;
    }
    if (isJSONDocument(type)) {
      return <JSONViewer {...viewerProps} />;
    }

    return null;
  };

  return (
    <>
      {isLoading && (
        <div className="flex justify-center items-center h-full">
          <DocumentPreviewLoader />
        </div>
      )}
      {error && !isLoading && <DocumentError onDownload={handleDownload} />}
      {!error && renderViewer()}
    </>
  );
};
