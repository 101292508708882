import * as Sentry from "@sentry/react";

import { CustomRequestInit } from "@/api/rest/core/types";

import { getPlatform } from "./platform";

// Common metadata collector for both spans and errors
interface RequestMetadata {
  url?: string;
  method?: string;
  requestBody?: any;
  responseBody?: any;
  responseStatus?: number;
  responseHeaders?: Record<string, string>;
  timestamp?: string;
  environment?: string;
  platform?: string;
}

// Collect complete request metadata
const collectRequestMetadata = (
  url?: string,
  method?: string,
  options?: CustomRequestInit,
  response?: Response,
  responseBody?: any,
): RequestMetadata => {
  const metadata: RequestMetadata = {
    url,
    method,
    timestamp: new Date().toISOString(),
    environment: import.meta.env.MODE,
    platform: getPlatform(),
  };

  // Add request data if available
  if (options?.body) {
    metadata.requestBody =
      options.body instanceof FormData ? "FormData" : options.body;
  }

  // Add response data if available
  if (response) {
    metadata.responseStatus = response.status;
    metadata.responseBody = responseBody;

    // Collect relevant headers
    const relevantHeaders = [
      "content-type",
      "content-length",
      "x-request-id",
      "cf-ray",
    ];

    metadata.responseHeaders = relevantHeaders.reduce(
      (acc, header) => {
        const value = response.headers.get(header);
        if (value) {
          acc[header] = value;
        }
        return acc;
      },
      {} as Record<string, string>,
    );
  }

  return metadata;
};

export const createSpanAttributes = (url: string, method: string) => ({
  "http.url": new URL(url).pathname,
  "http.method": method,
  environment: import.meta.env.MODE,
});

export const updateSpanWithResponse = (
  span: Sentry.Span,
  response: Response,
) => {
  span.setAttributes({
    "http.status_code": response.status,
  });

  if (!response.ok) {
    span.setStatus(Sentry.getSpanStatusFromHttpCode(response.status));
  }
};

export const sendToSentry = (
  error: Error,
  context: {
    url?: string;
    method?: string;
    options?: CustomRequestInit;
    response?: Response;
    responseBody?: any;
    additionalContext?: Record<string, any>;
  },
) => {
  const { url, method, options, response, responseBody, additionalContext } =
    context;

  Sentry.withScope((scope) => {
    // Collect complete request metadata
    const metadata = collectRequestMetadata(
      url,
      method,
      options,
      response,
      responseBody,
    );

    // Set error fingerprint for better grouping
    scope.setFingerprint([
      "api-error",
      metadata.url || "unknown-url",
      metadata.method || "unknown-method",
      String(metadata.responseStatus || "unknown-status"),
    ]);

    // Add rich context to error
    scope.setContext("request", {
      url: metadata.url,
      method: metadata.method,
      headers: options?.headers,
      body: metadata.requestBody,
    });

    if (response) {
      scope.setContext("response", {
        status: metadata.responseStatus,
        headers: metadata.responseHeaders,
        body: metadata.responseBody,
      });
    }

    scope.setContext("environment", {
      mode: metadata.environment,
      platform: metadata.platform,
      timestamp: metadata.timestamp,
    });

    // Add any additional context
    if (additionalContext) {
      scope.setContext("additional", additionalContext);
    }

    // Set error level based on response status
    if (response?.status) {
      scope.setLevel(response.status >= 500 ? "error" : "warning");
    }

    Sentry.captureException(error);
  });
};
