import { UseQueryResult } from "@tanstack/react-query";
import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiResponse } from "@/api/rest/core/types";
import { AccountInfo } from "@/api/rest/models/account";
import { logout } from "@/api/rest/services/authService";

import { NavbarLogo } from "./NavbarLogo";
import { NavbarMenu } from "./NavbarMenu";
// Memoize child components to prevent unnecessary re-renders
const MemoizedNavbarLogo = memo(NavbarLogo);
const MemoizedNavbarMenu = memo(NavbarMenu);

interface NavbarProps {
  households: any[];
  currentHousehold: UseQueryResult<ApiResponse<any>, unknown>;
  account: AccountInfo | null;
}

const Navbar = ({ households, currentHousehold, account }: NavbarProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);
  const householdData = currentHousehold.data?.result;

  const handleNavigate = useCallback(
    (path: string) => {
      setIsOpen(false);
      navigate(path);
    },
    [navigate],
  );

  const handleLogout = useCallback(async () => {
    await logout();
  }, []);

  const logoProps = useMemo(() => {
    return {
      householdData,
      onToggleMenu: handleToggleMenu,
    };
  }, [householdData, handleToggleMenu]);

  const menuProps = useMemo(() => {
    return {
      isOpen,
      accountName: account?.name ?? "",
      households,
      onNavigate: handleNavigate,
      onLogout: handleLogout,
    };
  }, [isOpen, account?.name, households, handleNavigate, handleLogout]);

  return (
    <>
      <nav className="min-w-[240px] flex flex-col justify-stretch bg-white">
        <MemoizedNavbarLogo {...logoProps} />
        <MemoizedNavbarMenu {...menuProps} />
      </nav>
    </>
  );
};

// Memoize the entire Navbar component
export default memo(Navbar);
