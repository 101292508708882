import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/et";
import "dayjs/locale/lv";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(RelativeTime);
dayjs.extend(LocalizedFormat);
dayjs.locale("et");

export default dayjs;
export { Dayjs };
