import { useCallback, useEffect, useMemo, useState } from "react";

import { loadDocument } from "@/api/rest/services/documentService";
import { sendToSentry } from "@/utils/sentry";

import { ViewerProps } from "../types";

export const JSONViewer: React.FC<ViewerProps> = ({
  document,
  onSuccess,
  onError,
  className,
}) => {
  const [jsonContent, setJsonContent] = useState<string>();

  useEffect(() => {
    loadDocument(document)
      .then((res) => {
        setJsonContent(res);
        onSuccess();
      })
      .catch((error) => {
        sendToSentry(error, {
          additionalContext: {
            component: "JSONViewer",
            documentId: document.id,
          },
        });
        onError(error);
      });
  }, [document, onError, onSuccess]);

  const parseResult = useMemo(() => {
    if (!jsonContent) {
      return null;
    }
    try {
      return JSON.parse(jsonContent);
    } catch (e) {
      sendToSentry(e as Error, {
        additionalContext: {
          component: "JSONViewer",
          jsonContent,
        },
      });
      return null;
    }
  }, [jsonContent]);

  const itemPreview = useCallback((item: unknown, index: number) => {
    return (
      <div key={index} className="bg-slate-100 p-2 rounded">
        {Object.entries((item as Record<string, unknown>) ?? {}).map(
          ([key, value]) => (
            <div key={key}>
              <span className="font-bold">
                {key.charAt(0).toUpperCase() + key.slice(1)}:{" "}
              </span>
              <span className="text-wrap">
                {typeof value === "object"
                  ? JSON.stringify(value, null, 2)
                  : String(value)}
              </span>
            </div>
          ),
        )}
      </div>
    );
  }, []);

  return (
    <div
      className={`flex flex-col gap-2 p-4 overflow-y-auto scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 ${className}`}
    >
      {!parseResult && !jsonContent && <div>Loading...</div>}
      {!parseResult && jsonContent && <div>{jsonContent}</div>}
      {parseResult &&
        (Array.isArray(parseResult)
          ? parseResult.map((item, index) => itemPreview(item, index))
          : itemPreview(parseResult, 0))}
    </div>
  );
};

export const isJSONDocument = (type: string): boolean => {
  return type === "json";
};
