export type ApiResponse<Type> =
  | {
      success: true;
      result: Type;
    }
  | {
      success: false;
      message: string;
      result: undefined;
    };

export interface AuthResponse {
  accessToken: string;
}

export interface CustomRequestInit extends RequestInit {
  headers?: HeadersInit;
}

export interface FetchWithAuthOptions extends CustomRequestInit {
  requestContext?: string; // e.g., 'AUTH', 'USER_SETTINGS', etc.
  errorHandlers?: {
    [statusCode: number]: (response: Response, responseBody: any) => void;
  };
}

export enum ApiErrorType {
  UNAUTHORIZED = "UNAUTHORIZED",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  NOT_FOUND = "NOT_FOUND",
  SERVER_ERROR = "SERVER_ERROR",
}

export class ApiError extends Error {
  constructor(
    public readonly type: ApiErrorType,
    message: string,
    public readonly details?: unknown,
  ) {
    super(message);
    this.name = "ApiError";
  }
}
