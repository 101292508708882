import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface DocumentErrorViewProps {
  onDownload: () => void;
}

const DocumentErrorView = ({ onDownload }: DocumentErrorViewProps) => {
  const { t } = useTranslation();

  return (
    <div className={`flex items-center justify-center h-full bg-slate-50`}>
      <div className="flex flex-col items-center max-w-md p-6 text-center">
        <div className="mb-4 p-3 bg-red-100 rounded-full">
          <svg
            className="w-6 h-6 text-red-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>

        <h3 className="mb-2 text-lg font-semibold text-gray-900">
          {t("files.previewError.title")}
        </h3>

        <p className="mb-4 text-gray-600">{t("files.previewError.message")}</p>

        <button
          onClick={onDownload}
          className="flex items-center px-4 py-2 text-sm font-medium text-white bg-dobu-orange rounded-md hover:bg-dobu-orange-focus focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dobu-orange"
        >
          <ArrowDownTrayIcon className="w-4 h-4 mr-2" />
          {t("files.download")}
        </button>
      </div>
    </div>
  );
};

export default DocumentErrorView;
