import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import { useAccount } from "@/api/hooks/useAccount";

export const UserIdentifier = () => {
  const posthog = usePostHog();
  const account = useAccount();

  useEffect(() => {
    if (!account || !posthog) {
      return;
    }

    posthog.identify(account.id.toString(), {
      email: account.email,
      name: account.name,
    });
  }, [posthog, account]);

  return null;
};
