import React, { Component, ReactNode } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

// Reusable error message component
const ErrorMessage = ({
  title,
  message,
  error,
}: {
  title: string;
  message: string;
  error?: Error | null;
}) => (
  <div className="min-h-screen flex items-center justify-center p-4">
    <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg border border-red-200">
      <h2 className="text-2xl font-bold text-red-700 mb-4">{title}</h2>
      <p className="text-gray-600 mb-4">{message}</p>
      {error && process.env.NODE_ENV === "development" && (
        <pre className="mt-4 p-4 bg-gray-50 rounded text-sm text-gray-700 overflow-auto">
          {error.stack}
        </pre>
      )}
      <button
        onClick={() => (window.location.href = "/")}
        className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
      >
        Return to Home
      </button>
    </div>
  </div>
);

// Router error wrapper component
export const RouterErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    // Handle routing errors (404, etc)
    return (
      <ErrorMessage
        title={`${error.status} ${error.statusText}`}
        message={
          error.data?.message || "An error occurred while loading this page"
        }
      />
    );
  }

  // Handle other errors
  const errorObject = error as Error;
  return (
    <ErrorMessage
      title="Unexpected Error"
      message="An unexpected error occurred. Please try again later."
      error={errorObject}
    />
  );
};

// Props interface for the component error boundary
interface Props {
  children: ReactNode;
  fallback?: React.ComponentType<{ error: Error }>;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

// Component error boundary
export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  // componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
  //   console.error("Error caught by boundary:", error, errorInfo);
  // }

  render() {
    if (this.state.hasError && this.state.error) {
      return (
        <ErrorMessage
          title="Component Error"
          message="An error occurred in this component."
          error={this.state.error}
        />
      );
    }

    return this.props.children;
  }
}
