import { useMemo } from "react";

import { useAccount } from "@/api/hooks/useAccount.ts";
import { HouseholdData } from "@/api/rest/models/household";

import ChatSubmission from "./ChatSubmission";
import DirectSubmissionForm from "./DirectSubmissionForm";

interface NewClaimProps {
  householdData: HouseholdData;
  refreshCases: () => void;
}

const NewClaim: React.FC<NewClaimProps> = ({ householdData, refreshCases }) => {
  const account = useAccount();

  const isHaldur = useMemo(
    () => householdData.household.title === "Haldur",
    [householdData],
  );

  const availableAccounts = useMemo(() => {
    const accountsInHousehold = householdData.accounts.map((a) => a.id);
    return (
      account?.accounts.filter((a) => accountsInHousehold.includes(a.id)) ?? []
    );
  }, [householdData, account]);

  return (
    <div className="flex flex-grow basis-1/2 flex-col gap-4">
      {isHaldur ? (
        <DirectSubmissionForm
          householdData={householdData}
          availableAccounts={availableAccounts}
          refreshCases={refreshCases}
        />
      ) : (
        <ChatSubmission
          householdData={householdData}
          availableAccounts={availableAccounts}
          refreshCases={refreshCases}
        />
      )}
    </div>
  );
};

export default NewClaim;
